import { apiHeaders } from '../../utils/utils'
import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'
import { LOCAL_STORAGE_KEYS } from '@/utils/constants'

export const getStoresByOrderId = (orders) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.warehouseOrder,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
    ),
    data: {
      orders,
    },
  })
